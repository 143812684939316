.mentions {
  margin: 1rem 0;
  width: 90%;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-size: 16px;
}
.mentions--multiLine .mentions__highlighter {
  padding: 0 0.5rem;
  border: 0 !important;
}
.mentions--multiLine .mentions__input {
  padding: 0 0.5rem;
  outline: 0;
  border: 0;
}

.mentions__suggestions__list {
  background-color: white;
  font-size: 12px;
  line-height: 14px;
  padding: 5px;
  border: 1px solid lightgray;
  max-height: 400px;
  background-color: white;
  overflow: auto;
}

.mentions__suggestions__item {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0;
}

.mentions__suggestions__item--focused {
  background-color: #ccc;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #32a2db;
  pointer-events: none;
}

.mentions__mention_users {
  position: relative;
  z-index: 1;
  color: #eece23;
  pointer-events: none;
}
