.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker-popper {
  z-index: 2;
}

input::placeholder { /* Most modern browsers support this now. */
  color:    rgb(247, 242, 242);
}
