.unlike-button {
    color: gray; /* Default color */
    font-size: 1.2rem; /* Default font size */
    cursor: pointer; /* Makes the icon clickable */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .unlike-button:hover {
      color: rgb(205, 61, 29);
    }

  .unlike-button.selected {
      color: rgb(255, 0, 0); /* Selected color */
  }