.hover-icon {
  color: gray; /* Default color */
  font-size: 1.2rem; /* Default font size */
  cursor: pointer; /* Makes the icon clickable */
  transition: color 0.3s ease; /* Smooth color transition */
}

.hover-icon:hover {
    color: rgb(41, 37, 37);
  }
  