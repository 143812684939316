.like-button {
    color: gray; /* Default color */
    font-size: 1.2rem; /* Default font size */
    cursor: pointer; /* Makes the icon clickable */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .like-button:hover {
      color: rgb(7, 130, 35);
    }

  .like-button.selected {
      color: rgb(7, 130, 35);
    }