#dnd-table table {
  table-layout: fixed;
}
#dnd-table thead th {
  background-color: #f5f5f5;
}

#dnd-table tr {
  display: table;
  width: 100%;
}

#dnd-table td {
  box-sizing: border-box;
}
  